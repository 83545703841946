.linkSite {
  background-color: #f5f7f4;
  min-height: 100vh;
}

.linkHeader {
  position: relative;
  width: 100%;
  height: 80px;
  padding-top: 1em;
  background-color: white;
  display: inline-block;
}

.linkHeaderLogo {
  width: 64px;
  height: 64px;
  margin-left: 1em;
  display: inline-block;
  align-items: center;
}

.linkLogoutLocation {
  display: block;
  align-items: center;
  height: 100%;
  margin-right: 2em;
  float: right;
  margin-top: 20px;
}

.linkLogoutButton {
  border: none;
  cursor: pointer;
  background-color: white;
  font-weight: bold;
  font-size: 16px;
}

.linkSearchLocation {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.linkSearchButton {
  margin-left: 1em;
  background-color: #66c6b6;
  border: none;
  border-radius: 13px;
  width: 56px;
  height: 36px;
  margin-top: 4em;
  margin-bottom: 4em;
  cursor: pointer;
}
.linkAddButton {
  color: white;
  position: relative;
  background-color: #66c6b6;
  border-radius: 13px;
  border: none;
  width: 56px;
  height: 36px;
  cursor: pointer;
  font-size: 24px;
  left: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkSearchInput {
  width: 516px;
  height: 36px;
  border-radius: 13px;
  border: 1px #d6d6d6 solid;
  margin-top: 4em;
  margin-bottom: 4em;
  padding-left: 2em;
}

.linkTableLocation {
  display: flex;
  justify-content: center;
}

.table {
  border-collapse: collapse;
  width: 1610px;
}

.linkTableheader {
  background-color: #f4f4f4;
  text-align: left;
  border: none;
  height: 64px;
}

.linkTable {
  background-color: #ffffff;
  border-radius: 8px;
}

.linkTableBody {
  background-color: white;
}

.linkTableRows {
  margin-top: 3em;
  padding-bottom: 2em;
  height: 6.1em;
}

.linkTableMargin {
  margin-left: 1em;
}

.linkDownloadQRButton {
  border: none;
  background-color: white;
  width: 39px;
  height: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.6em;
}

.linkApplink {
  font-weight: 500;
  display: inline-block;
  margin-right: 1em;
}

.truncatedLink {
  display: inline-block;
}

.linkCopyButtonStyle {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}

.linkEditButtonStyle {
  background-color: white;
  offset: 0 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
}

.linkEditIconStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  max-width: 50em;
  width: 100%;
}

.modal-content .input-group {
  display: flex;
  margin-bottom: 20px;
}

.modal-content label {
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 13px;
  border: solid 1px #d6d6d6;
}

.linkAddProjectTitle {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 1em;
  display: inline-flex;
  color: #66c6b6;
  margin-left: 15em;
}

.linkQRCodePozition {
  display: flex;
  justify-content: center;
}

.linkQRcode {
  margin-top: 1em;
  width: 3.5em;
}

.linkCreateButton {
  background-color: #66c6b6;
  border-radius: 13px;
  color: white;
  border: solid 1px;
  height: 36px;
  cursor: pointer;
  float: right;
}

.linkCancelButton {
  background-color: #66c6b6;
  border-radius: 13px;
  color: white;
  border: solid 1px;
  height: 36px;
  cursor: pointer;
  float: right;
  margin-left: 1em;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.page-item {
  display: inline-block;
  margin: 0 10px;
}

.page-link {
  display: block;
  padding: 10px 20px;
  background-color: #66c6b6;
  border: 1px solid #ddd;
  border-radius: 13px;
  text-align: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
  width: 56px;
  height: 36px;
}

.page-link.active {
  background-color: #78ebd7;
  color: #fff;
}

.modal {
  opacity: 0;
}

.modalRowDisplays {
  display: flex;
  align-items: center;
}

.modalLabelDisplay {
  display: inline-block;
  width: 120px;
  text-align: right;
  margin-right: 0.5em;
  color: #66c6b6;
  font-weight: bold;
}

.modalInputDisplay {
  display: inline-block;
}

.modalDleteButton {
  display: inline-flex;
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  float: right;
  margin-top: 0.7em;
}

.deleteLogo {
  height: 1.2em;
}

.buttonSpace {
  margin: 0 15px 0 15px;
}

.characterDisplay{
  margin-left: 120px;
  font-size: 12px;
}